<script>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  import { Dropdown } from "bootstrap";

  export default {
    name: "AppPageHeaderDropdown",
    props: {
      id: {
        type: String,
        required: true,
      },
      buttonLabel: {
        type: String,
        default: "",
        required: false,
      },
      buttonTitle: {
        type: String,
        required: true,
      },
      secondIcon: {
        type: String,
        default: "",
        required: false,
      },
    },
  };
</script>

<template>
  <div class="dpw-page-header-dropdown dropdown">
    <slot name="trigger">
      <button
        :id="id"
        type="button"
        class="dpw-page-header-dropdown__trigger-button"
        :title="buttonTitle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        aria-label="Toggle dropdown"
      >
        <span v-if="buttonLabel">{{ buttonLabel }}</span>
        <span v-if="secondIcon" :class="['dps-icon', 'dps-icon--' + secondIcon]"></span>
        <span class="dps-icon dps-icon--chevron-down-small"></span>
      </button>
    </slot>

    <ul class="dropdown-menu" :aria-labelledby="id">
      <slot />
    </ul>
  </div>
</template>

<style lang="scss">
  @use "diplanung-style/src/scss/variables/link" as link-variables;
  @use "diplanung-style/src/scss/variables/transitions" as transitions-variables;

  .dpw-page-header-dropdown {
    display: inline-flex;

    &__trigger-button {
      background: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 0;
      display: inline-flex;
      align-items: center;
      font-weight: 600;

      &:hover,
      &:focus {
        color: var(--dps-color-primary);
      }

      .dps-icon {
        font-size: 22px;
      }

      &.show {
        .dps-icon--chevron-down-small {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-menu {
      --bs-dropdown-padding-y: 16px;
      --bs-dropdown-padding-x: 16px;
      --bs-dropdown-box-shadow: 0 1px 0 rgba(0 0 0 / 7%);
      --bs-dropdown-border-radius: 0;
      --bs-dropdown-border-color: var(--dps-color-light-gray);
      --bs-dropdown-min-width: 0;
      --bs-dropdown-font-size: 15px;
      --bs-dropdown-divider-bg: var(--dps-color-light-gray);
      --bs-dropdown-divider-margin-y: 16px;
      --bs-dropdown-item-padding-x: 0;
      --bs-dropdown-item-padding-y: 0;
      --bs-dropdown-link-color: var(--dps-color-black);
      --bs-dropdown-link-hover-color: var(--dps-color-primary);
      --bs-dropdown-link-hover-bg: transparent;
      --bs-dropdown-link-active-color: var(--dps-color-primary);
      --bs-dropdown-link-active-bg: transparent;
      --bs-dropdown-link-disabled-color: var(--dps-color-dark-gray);

      > li + li {
        margin-top: 16px;
      }
    }

    .dps-link {
      font-size: 15px;
      color: var(--dps-color-black);
      font-weight: 400;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: var(--dps-color-primary);
      }
    }

    .dropdown-item {
      font-size: 15px;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      min-height: 24px;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }

      .dps-icon {
        font-size: 24px;
      }
    }
  }
</style>
