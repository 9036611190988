/**
 * @typedef {import('axios').CreateAxiosDefaults} CreateAxiosDefaults
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

/**
 * Axios basic configuration
 */
import axios from "axios";

import { useAuthStore } from "@/stores/auth.js";

/** @type {CreateAxiosDefaults} */
const createAxiosDefaults = {
  headers: {
    "Content-type": "application/json",
  },
};

/**
 * Create the instance of Axios
 */

/** @type {AxiosInstance} */
export const axiosInstance = axios.create(createAxiosDefaults);

/**
 * Auth interceptors
 * @description Add auth token to every outgoing request.
 * @param {CreateAxiosDefaults} config
 */

export const authInterceptor = (config) => {
  const authStore = useAuthStore();
  const bearerToken = authStore.token || "";

  config.headers.Authorization = `Bearer ${bearerToken}`;

  return config;
};

export const traceIdInterceptor = (response) => {
  // prevent output in vitest
  if (typeof process === "object") return;

  const stylesHeadline = "font-size: 14px; font-weight: 700;";
  const responseUrl = response.request.responseURL || "";
  let urlInformation = responseUrl.substring(responseUrl.lastIndexOf("/") + 1);

  urlInformation = ` (${urlInformation.split("?")[0]})`;

  console.groupCollapsed(
    `%cRequest information%c${urlInformation}`,
    stylesHeadline,
    "font-size: 10px; font-weight: 400;",
  );
  console.info(`ResponseURL:  ${responseUrl}`);
  console.info(
    `TraceId:      ${response?.headers?.traceid || "TraceId steht nicht zur Verfügung"}`,
  );
  console.groupEnd();

  return response;
};

/** Adding the request interceptors */

export const authRequestInterceptor = axiosInstance.interceptors.request.use(authInterceptor);

/** Adding the response interceptors */

export const traceIdResponseInterceptor =
  axiosInstance.interceptors.response.use(traceIdInterceptor);
