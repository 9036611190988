import moment from "moment";
import { defineStore } from "pinia";

import { getPlanID } from "@/router/services.js";
import {
  formatTaskDate,
  getAppointmentColor,
  getAppointmentResult,
  getProperAppointment,
} from "@/services/appointment.js";
import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.js";
import { useFundamentalStore } from "@/stores/fundamental.js";
import { useProceedingsStore } from "@/stores/proceedings.js";
import { useTasksStore } from "@/stores/tasks.js";

export const useAppointmentsStore = defineStore("appointments", {
  state: () => ({
    appointmentContainer: {},
    pvAppointmentContainer: {},
    scheduleContainer: {},
    activeVts: null,
    dateArray: [],
  }),
  actions: {
    /**
     * Loads all appointments of a specific proceeding and caches it.
     * @param {Object} payload
     * @param {String|Object|undefined} payload.proceedingID
     *   If no ID is given the ID is derived from the path, if the ID is provided encapsulated
     *   within an object, a forced reload is done.
     * @param {Boolean} payload.isPV
     *   check if proceeding is only parallel proceeding and appointments need to be stored in different structure.
     * @returns {Promise}
     */
    loadProceedingAppointmentsByID({ proceedingID, isPV = false }) {
      const appStore = useAppStore();
      let forceReload;

      if (proceedingID && proceedingID.constructor.name === "String") {
        forceReload = false;
      } else if (proceedingID) {
        proceedingID = proceedingID.planID;
        forceReload = true;
      } else {
        proceedingID = getPlanID();
        forceReload = false;
      }

      if (
        this.appointmentContainer[proceedingID] === undefined ||
        this.appointmentContainer[proceedingID].loading !== undefined ||
        (isPV && this.pvAppointmentContainer[proceedingID] === undefined) ||
        (isPV && this.pvAppointmentContainer[proceedingID].loading !== undefined) ||
        forceReload
      ) {
        if (!isPV) {
          this.appointmentContainer[proceedingID] = { loading: true };
        } else {
          this.pvAppointmentContainer[proceedingID] = { loading: true };
        }

        return new Promise((resolve, reject) => {
          openAPIFactory
            .terminResourceApiFactory()
            .getTermine(proceedingID)
            .then((response) => {
              if (isPV) {
                this.pvAppointmentContainer[proceedingID] = response.data;
              } else {
                this.appointmentContainer[proceedingID] = response.data;
              }

              resolve();
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Die Ermittlung der Verfahrenstermine ist fehlgeschlagen!",
              });

              reject();
            });
        });
      }
      return new Promise((resolve) => {
        resolve();
      });
    },
    /**
     * Loads the schedule relevant data of a specific proceeding and caches it.
     * @param {String, Object, undefined} proceedingID
     *   If no ID is given the ID is derived from the path, if the ID is provided encapsulated
     *   within an object, a forced reload is done.
     * @returns {void}
     */
    loadScheduleRelevantDataByID(proceedingID) {
      const appStore = useAppStore();
      let forceReload;

      if (proceedingID && proceedingID.constructor.name === "String") {
        forceReload = false;
      } else if (proceedingID) {
        proceedingID = proceedingID.planID;
        forceReload = true;
      } else {
        proceedingID = getPlanID();
        forceReload = false;
      }

      if (
        this.scheduleContainer[proceedingID] === undefined ||
        this.scheduleContainer[proceedingID].loading !== undefined ||
        this.scheduleContainer[proceedingID].invalidated ||
        forceReload
      ) {
        this.scheduleContainer[proceedingID] = { loading: true };
        openAPIFactory
          .terminResourceApiFactory()
          .getZeitplanung(proceedingID)
          .then((response) => {
            this.setScheduleContainer({
              proceedingID,
              scheduleData: response.data,
            });
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Die Ermittlung der Zeitplanung ist fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * (De-)activates the monitoring of a specific proceeding.
     *
     * Requests to the K1 will only be made with valid arguments.
     * @param {Object} payload
     * @param {String} payload.proceedingID
     *   The proceeding ID the monitoring state should get altered for.
     * @param {Boolean} payload.monitoringState
     *   True, if a schedule monitoring should be performed.
     * @param {Object|undefined} payload.nonMonitoringReason
     *   Object containing the non-monitoring reason. Must be set for deactivated monitorings.
     * @returns {void}
     */
    setScheduleMonitoringStateByID({ proceedingID, monitoringState, nonMonitoringReason }) {
      proceedingID = proceedingID !== undefined ? proceedingID : getPlanID();

      const proceedingsStore = useProceedingsStore();
      const appStore = useAppStore();
      const proceeding = proceedingsStore.getProceedingWorkingCopy(proceedingID);
      const proceedingName = proceeding.verfahrenssteuerung.name;
      const payload = {
        aktiv: monitoringState,
      };

      if (!monitoringState) {
        payload["nichtMonitoringGrund" + proceedingName] = nonMonitoringReason;
      }

      if (monitoringState || (!monitoringState && nonMonitoringReason)) {
        appStore.showPageLoadingIndicator({
          id: "setScheduleMonitoringStateByID",
          text:
            "Einen Moment bitte, das Monitoring wird " +
            (monitoringState ? "aktiviert" : "deaktiviert"),
        });

        openAPIFactory
          .terminResourceApiFactory()
          .setMonitoringState(payload, proceedingID)
          .then((response) => {
            appStore.hidePageLoadingIndicator("setScheduleMonitoringStateByID");

            this.setScheduleContainer({
              proceedingID,
              scheduleData: JSON.parse(response),
            });
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("setScheduleMonitoringStateByID");

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das (De-)Aktivieren des Monitorings ist fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * Sets the schedule data of a specific proceeding.
     * @param {Object} payload
     * @param {String} payload.proceedingID
     *   The proceeding ID.
     * @param {Object} payload.scheduleData
     *   The schedule data as derived from the K1.
     * @returns {void}
     */
    setScheduleContainer({ proceedingID, scheduleData }) {
      this.scheduleContainer[proceedingID] = {
        monitoringAktiv: scheduleData.monitoringStatus.aktiv,
        nichtMonitoringGrundBezirk: scheduleData.monitoringStatus.nichtMonitoringGrundBezirk
          ? scheduleData.monitoringStatus.nichtMonitoringGrundBezirk
          : undefined,
        nichtMonitoringGrundSenat: scheduleData.monitoringStatus.nichtMonitoringGrundSenat
          ? scheduleData.monitoringStatus.nichtMonitoringGrundSenat
          : undefined,
        termine: scheduleData.termine,
        invalidated: false,
      };
    },
    /**
     * Update a proceeding appointment.
     * @param {String} planID
     *   The proceeding ID this appointment data is for
     * @param {String} vtsID
     *   The ID of the VTS the appointment is located in
     * @param {String} vtsRound
     *   The number of the round of the VTS
     * @param {Object} appointmentData
     *   The complete appointment data object
     * @param {Boolean} invalidateSchedule
     *   True if the schedule page should get invalidated and a reload should be forced
     * @param {Boolean} invalidateAppointments
     *   True if the appointment data should forcefully be reloaded
     * @returns {void}
     */
    updateProceedingAppointment({
      planID,
      vtsID,
      vtsRound,
      appointmentData,
      invalidateSchedule,
      invalidateAppointments,
    }) {
      const tasksStore = useTasksStore();
      const vtsRoundObj = tasksStore.vtsRoundData(planID, vtsID, vtsRound);
      const appointmentIndex = vtsRoundObj.termine.findIndex(
        (appointment) => appointment.terminID === appointmentData.terminID,
      );

      vtsRoundObj.termine[appointmentIndex] = appointmentData;

      tasksStore.setVtsRound({ planID, vtsID, roundID: vtsRound, roundData: vtsRoundObj });

      if (invalidateSchedule && this.scheduleContainer[planID] !== undefined) {
        this.scheduleContainer[planID].invalidated = true;
      }

      if (invalidateAppointments && this.appointmentContainer[planID] !== undefined) {
        this.loadProceedingAppointmentsByID({ proceedingID: { planID } });
      }
    },
    /**
     * Returns an object for one date
     * @param {Object} appointment appointment
     * @returns {Object} line appointment
     */
    getShipmentDate(appointment) {
      return {
        name: "Versanddatum",
        date: appointment?.sitzung?.versanddatum
          ? formatTaskDate(appointment.sitzung.versanddatum)
          : "",
        dateColor: "",
        status: "-",
        result: "-",
      };
    },
    /**
     * Returns an object for one date
     * @param {Object} appointment appointment
     * @param datumsStatus
     * @returns {Object} line appointment
     */
    getAppointmentDate(appointment, datumsStatus) {
      const properAppointment = getProperAppointment(appointment, datumsStatus);
      const appointmentResult = getAppointmentResult(appointment);
      const appointmentColor = getAppointmentColor(appointment, datumsStatus);
      let formattedDate;

      if (appointment.datumstyp?.code === "0200") {
        formattedDate = [properAppointment.beginn, properAppointment.ende].map((elem) =>
          formatTaskDate(elem),
        );
      } else {
        formattedDate =
          properAppointment.ende !== undefined ? formatTaskDate(properAppointment.ende) : "-";
      }

      return {
        name: appointment.beschreibung,
        date: formattedDate,
        dateColor: appointmentColor,
        status: appointment.datumsstatus ? appointment.datumsstatus.name : "-",
        result: appointmentResult ? appointmentResult.name : "-",
      };
    },
    /**
     * Returns an object for one date
     * @returns {Object} date object
     * @param name
     * @param date
     */
    getActionItemDate(name, date) {
      let status = "nicht gesetzt";
      let dateColor = "#6C6E72";

      if (date) {
        const currentDate = moment().startOf("day");
        const setDate = moment(date).startOf("day");

        if (setDate.isAfter(currentDate)) {
          status = "geplant";
          dateColor = "#04071A";
        } else if (setDate.isSame(currentDate)) {
          status = "geplant";
          dateColor = "#04071A";
        } else if (setDate.isBefore(currentDate)) {
          status = "stattgefunden ";
          dateColor = "#00913D";
        }
      }

      return {
        name: name,
        date: date ? formatTaskDate(date) : "-",
        status: status,
        dateColor: dateColor,
      };
    },
    /**
     * Update the dateArray based on the appointments and datumsStatus.
     * This function retrieves shipment dates and appointment dates from the appointments
     * and adds them to the dateArray.
     */
    updateDateArray() {
      const dates = [];
      const appointments = this.activeVts?.termine || [];

      appointments.forEach((appointment) => {
        if (appointment.sitzung?.versanddatum !== null) {
          dates.push(this.getShipmentDate(appointment));
        }
        if (getProperAppointment(appointment, this.datumsStatus) !== null) {
          dates.push(this.getAppointmentDate(appointment, this.datumsStatus));
        }
      });

      const actionItemDates = [];
      const unterverfahrensteilschritte = this.activeVts?.unterverfahrensteilschritte || [];

      unterverfahrensteilschritte.forEach((teilSchritte) => {
        const unterAufgaben = teilSchritte?.aufgaben?.[0]?.unterAufgaben || [];

        unterAufgaben.forEach((unterAufgabe) => {
          const { actionItems } = unterAufgabe;

          if (actionItems && actionItems.length) {
            actionItems
              .filter((item) => item.typ === "ADD_DATE")
              .forEach((item) => {
                actionItemDates.push(this.getActionItemDate(item.label, item.zeitraum?.ende));
              });
          }
        });
      });

      this.dateArray = [...actionItemDates, ...dates];
    },
  },
  getters: {
    /**
     * Returns all appointments of a given proceeding.
     * @returns {function(proceedingID: String, onlyStructuralSoundAppointments: Boolean, isPV: Boolean): Array}
     */
    getAppointmentsByProceedingID() {
      const fundamentalStore = useFundamentalStore();

      return (proceedingID, onlyStructuralSoundAppointments = true, isPV = false) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        const selectedAppointmentContainer = isPV
          ? this.pvAppointmentContainer
          : this.appointmentContainer;
        let appointments =
          selectedAppointmentContainer[proceedingID] !== undefined &&
          selectedAppointmentContainer[proceedingID].loading === undefined
            ? selectedAppointmentContainer[proceedingID]
            : [];
        const datumsStatus = fundamentalStore.datumstatusByName;

        if (onlyStructuralSoundAppointments) {
          appointments = appointments.filter((appointment) => {
            if (appointment.datumsstatus) {
              switch (appointment.datumsstatus.code) {
                // stattgefunden
                case datumsStatus.stattgefunden:
                  return Boolean(appointment.stattgefundenerZeitraum);
                // prognostiziert
                case datumsStatus["initial prognostiziert"]:
                  return Boolean(appointment.initialPrognostizierterZeitraum);
                // berechnet
                case datumsStatus.berechnet:
                // geplant
                // eslint-disable-next-line no-fallthrough
                case datumsStatus.geplant:
                  return Boolean(appointment.geplanterOderBerechneterZeitraum);
                default:
                  return false;
              }
            }
            return false;
          });
        }

        return [...appointments];
      };
    },
    /**
     * Returns the schedule data object of a given proceeding ID.
     * @returns {function(proceedingID: String): Object}
     */
    getScheduleDataByProceedingID() {
      return (proceedingID) => {
        proceedingID = proceedingID === undefined ? getPlanID() : proceedingID;

        return this.scheduleContainer[proceedingID] !== undefined &&
          this.scheduleContainer[proceedingID].loading === undefined
          ? this.scheduleContainer[proceedingID]
          : {};
      };
    },
  },
});
