/* eslint-disable vue/max-len */
// stylelint-disable-next-line scss/at-import-partial-extension
import "@/styles/applications/dpw/index.scss";

import { createPinia } from "pinia";
import { createApp } from "vue";

import App from "@/App.vue";
import HelperFunctions from "@/plugins/helper-functions.js";
import router from "@/router";
import { NutzerResolver, setPageTitle, userHasPermissions } from "@/router/services.js";
import { logAppInfos } from "@/services/app.js";
import { useAppStore } from "@/stores/app.js";
import { useAuthStore } from "@/stores/auth.js";
/* eslint-enable vue/max-len */

const app = createApp(App);
const pinia = createPinia();

router.beforeEach(async (to) => {
  const isPublicPage = to.matched.some((record) => record.meta.isPublicPage);

  setPageTitle(to);

  if (!isPublicPage) {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    localStorage.setItem("isLogoutVisible", JSON.stringify(false));

    // Authenticates the user against Keycloak
    await authStore.init();

    //Token Refresh
    if (authStore.authenticated) {
      authStore.startRefreshTimer();
    } else {
      authStore.stopRefreshTimer();
    }

    // Resolve Services
    if (appStore.resolved["/nutzer"] !== true && !appStore.hasUserLoadingError) {
      // await Resolve of Nutzer
      await NutzerResolver();

      // log appInfos once
      await logAppInfos();

      // Check for user loading error after resolving and logging
      if (appStore.hasUserLoadingError) {
        return { name: "AppAccessDenied" };
      }
    }

    resolveServices(to);

    // redirect the user to the access denied page
    if (!hasPermissions(to)) return { name: "AppAccessDenied" };
  }
});

const resolveServices = (to) => {
  // get meta information
  const resolve = to.meta.resolve ?? {};

  Object.entries(resolve).forEach((service) => {
    service[1]();
  });
};

const hasPermissions = (to) => {
  const requiredPermissions = to.meta.requiredPermissions ?? [];

  if (Array.isArray(requiredPermissions) && requiredPermissions.length) {
    return userHasPermissions(requiredPermissions);
  } else if (typeof requiredPermissions === "object") {
    for (const value of Object.values(requiredPermissions)) {
      if (!userHasPermissions(value)) return false;
    }
  }
  return true;
};

// Custom directive providing a "v-click-outside" option on elements.
app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

// Custom directive providing a "v-tab-outside" option on elements.
app.directive("tab-outside", {
  mounted(el, binding) {
    el.tabOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("keydown", el.tabOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("keydown", el.tabOutsideEvent);
  },
});

const appMount = async (element) => {
  app.use(pinia);
  app.use(router);
  app.use(HelperFunctions);
  app.provide("uniqueIdProvider", app.config.globalProperties.$getUniqueId);

  app.mount(element);
};

const initializeApp = async () => {
  await appMount("#root");
};

initializeApp()
  .then(() => console.log("WeSIA successfully initialized!"))
  .catch(() => console.error(`WeSIA ${app.version} failed to initialize`));
